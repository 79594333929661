import { message } from "antd"
import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody, Row, Button } from "reactstrap"
import { jwtDecode } from "jwt-decode"

const UpdateOrderStatusModal = ({
  showModel,
  handleCloseModel,
  selectedOrder,
}) => {
  const [status, setStatus] = useState("select")

  useEffect(() => {
    if (selectedOrder) {
      setStatus(selectedOrder.Status)
    }
  }, [selectedOrder])

  const updateStatus = selectedOrder => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const token = user.token
    const decodedToken = jwtDecode(token)
    const { Username } = decodedToken
    const updated_by = Username
   

    fetchData(
      `${process.env.REACT_APP_BASE_URL}/orders/${selectedOrder.OrderID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: status,
          updated_by: updated_by,
        }),
      }
    )
      .then(data => {
      
        handleCloseModel()
      })
      .catch(err => {
        console.log(err)
      })
  }
  const fetchData = async (url, options) => {
    const response = await fetch(url, options)

    return response
  }

  return (
    <>
      {showModel && (
        <Modal isOpen={true} toggle={handleCloseModel}>
          <ModalHeader toggle={handleCloseModel}>
            <h2 className="min-w-[250px] text-lg font-bold text-gray-400 mb-2">
              Update Order Status
            </h2>
          </ModalHeader>
          <ModalBody>
            <div className="w-full flex justify-center items-center">
              <div className="w-full">
                <div className="">
                  <select
                    style={{
                      width: "160px",
                    }}
                    value={status}
                    name="status"
                    id="status"
                    className="w-full bg-gray-800 text-gray-400 py-2 px-2 rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-500"
                    onChange={e => setStatus(e.target.value)}
                  >
                    <option value="select">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="processing">Processing</option>
                    <option value="shipped">Shipped</option>
                    <option value="delivered">Delivered</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>

                <div className="">
                  <Button
                    color="dark"
                    className="text-gray-400 text-sm py-1 px-3 w-full rounded"
                    onClick={() => updateStatus(selectedOrder)}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default UpdateOrderStatusModal
