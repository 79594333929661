import React, { useEffect, useState } from "react"

const OrderForm = ({ selectedOrder, isDisabled, setSelectedOrder }) => {
  const initialOrderData = {
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    note: "",
  }
  const [orderData, setOrderData] = useState(initialOrderData)
  const { id, name, email, phone, note, address } = orderData

  useEffect(() => {
    setOrderData({
      id: selectedOrder.OrderID,
      name: selectedOrder.UserName,
      email: selectedOrder.Email,
      phone: selectedOrder.ContactNo,
      address: selectedOrder.ShippingAddress,
      note: selectedOrder.Note,
    })
  }, [selectedOrder])

  const handleChange = event => {
    const { id, name, value } = event.target
   
    setSelectedOrder({ ...selectedOrder, [name]: value })
  }
 
  return (
    <table className="w-full">
      <tbody>
        <tr>
          <td className="pr-4 text-right">
            <label htmlFor="orderID">Order ID :</label>
          </td>
          <td>
            <input
              id="id"
              name="OrderID"
              type="text"
              value={id}
              onChange={handleChange}
              disabled={true}
              style={{
             
                paddingLeft: "10px",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="pr-4 text-right">
            <label htmlFor="name">Name :</label>
          </td>
          <td>
            <input
              id="name"
              name="UserName"
              type="text"
              value={name}
              disabled={true}
              onChange={handleChange}
              style={{
             
                paddingLeft: "10px",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="pr-4 text-right">
            <label htmlFor="email">Email :</label>
          </td>
          <td>
            <input
              id="email"
              name="Email"
              type="text"
              value={email}
              disabled={true}
              onChange={handleChange}
              style={{
             
                paddingLeft: "10px",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="pr-4 text-right">
            <label htmlFor="phone">Phone :</label>
          </td>
          <td>
            <input
              name="ContactNo"
              id="phone"
              type="text"
              value={phone}
              disabled={true}
              onChange={handleChange}
              style={{
             
                paddingLeft: "10px",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="pr-4 text-right">
            <label htmlFor="address">Address :</label>
          </td>
          <td>
            <input
              id="address"
              name="ShippingAddress"
              type="text"
              value={address}
              disabled={true}
              onChange={handleChange}
              style={{
             
                paddingLeft: "10px",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            />
          </td>
        </tr>

        <tr>
          <td className="pr-4 text-right">
          <label style={{ paddingRight: "15px" }} htmlFor="note">
            Note :
          </label>
          </td>
          <td>
          <input
            id="note"
            name="Note"
            type="text"
            value={note}
            disabled={true}
            style={{
             
              paddingLeft: "10px",
              borderStyle: "solid",
              borderRadius: "5px",
            }}
          />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default OrderForm
