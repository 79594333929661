import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { FaEdit, FaSave, FaPrint, FaTimes } from "react-icons/fa"
import OrderForm from "./orderForm"
import DropDown from "./dropdown"
import { message } from "antd"
import OrderTable from "./orderTable"
import { isVisible } from "@testing-library/user-event/dist/utils"
const CustomButton = ({ handleCloseModel, setEdit }) => {
  const handleClick = () => {
    handleCloseModel()
    // setEdit(true)
  }

  return (
    <button
      className="flex items-center gap-3 bg-red-500 py-2 px-4 rounded ml-4"
      onClick={handleClick}
    >
      <FaTimes />
      Close
    </button>
  )
}
const ViewModel = ({
  showModel,
  handleCloseModel,
  handleCloseEdit,
  edit,
  selectedOrder,
  setSelectedOrder,
  products,
  setEdit,
}) => {
  const updateSelectedOrder = selectedOrder => {
    fetchData(
      `${process.env.REACT_APP_BASE_URL}/orders/update/${selectedOrder.OrderID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ selectedOrder }),
      }
    )
      .then(data => {
        if (data.status === 200) {
          message.success("Order Updated Successfully")
        }
      })
      .catch(err => {
        console.log(err)
        message.error("Error Updating Order")
      })
  }

  const fetchData = async (url, options) => {
    const response = await fetch(url, options)

    return response
  }

  return (
    <>
      {showModel && (
        <Modal isOpen={true} toggle={handleCloseModel}>
          <ModalHeader toggle={handleCloseModel}>
            <button style={isVisible ? { display: "none" } : {}}
              className="flex items-center gap-3 bg-green-500 py-2 px-4 rounded ml-4"
              onClick={handleCloseEdit}
            >
              <FaEdit />
              Edit
            </button>
          </ModalHeader>
          <ModalBody>
            <h2 className="min-w-[250px] md:min-w-[500px] text-lg font-bold text-gray-400 mb-2 text-center">
              Order Details
            </h2>

            <div className="w-full border border-gray-300 rounded-lg p-4">
              <div className="w-full">
                <div className="w-full text-left">
                  
                  <OrderForm
                    selectedOrder={selectedOrder}
                    isDisabled={edit}
                    setSelectedOrder={setSelectedOrder}
                  />
                </div>
              </div>
            </div>

            <div className="w-full border border-gray-300 rounded-lg p-4">
              <h2 className="text-lg mt-2 text-gray-400 text-center">
                Order Items
              </h2>
              {edit && (
                <DropDown
                  products={products}
                  selectedOrder={selectedOrder}
                  setSelectedOrder={setSelectedOrder}
                />
              )}
              <OrderTable
                items={selectedOrder.items}
                totalAmount={selectedOrder.TotalAmount}
                setSelectedOrder={setSelectedOrder}
                isEdit={edit}
              />
            </div>
            <div className="w-full flex justify-end py-2">
              {edit && (
                <button
                  onClick={updateSelectedOrder}
                  className="flex items-center gap-3 bg-green-500 py-2  mr-5 px-4 rounded"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <FaSave />
                  Save
                </button>
              )}
              <button
                className="flex items-center gap-3 bg-blue-500 py-2 px-4 rounded"
                onClick={() => window.print()}
                style={{
                  marginRight: "10px",
                }}
              >
                <FaPrint />
                Print
              </button>
              <CustomButton
                handleCloseModel={handleCloseModel}
                // setEdit={setEdit}
              />
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ViewModel
