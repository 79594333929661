import React, { useCallback, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import { jwtDecode } from "jwt-decode"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap"
import axios from "axios"
import { Space, message, Popconfirm } from "antd"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./datatables.scss"
import { Link } from "react-router-dom"
import { isObject } from "lodash"

const DataTableCategory = props => {
  const [selectedCategory, setSelectedCategory] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [editedValues, setEditedValues] = useState({
    NameEn: "",
    NameSi: "",
    NameTa: "",
  })

  const [data, setData] = useState({
    columns: [],
    rows: [],
  })

  const toggleModal = async () => {
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (isModalOpen && selectedCategory) {
      fetchCategoryDetails()
    }
  }, [isModalOpen, selectedCategory])

  const fetchCategoryDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/category/categoryById/${selectedCategory.id}`
      )

      if (response.ok) {
        const categoryDetails = await response.json()

        const imageFileName = Array.isArray(categoryDetails.Image)
          ? categoryDetails.Image[0]
          : categoryDetails.Image
        const cleanedFileName = imageFileName.replace(/[\[\]"]/g, "")

        setEditedValues({
          NameEn: categoryDetails.NameEn,
          NameSi: categoryDetails.NameSi,
          NameTa: categoryDetails.NameTa,
          Image: cleanedFileName,
        })
      } else {
        console.log("Failed to fetch category details:", response.statusText)
      }
    } catch (error) {
      console.error("Error fetching category details:", error)
    }
  }

  const handleInputChange = (field, value) => {
    setEditedValues(prevValues => ({
      ...prevValues,
      [field]: value,
    }))
  }

  const handleUpdate = async () => {

    try {
      const formDataToSend = new FormData()
      formDataToSend.append("categoryNameEnglish", editedValues.NameEn)
      formDataToSend.append("categoryNameSinhala", editedValues.NameSi)
      formDataToSend.append("categoryNameTamil", editedValues.NameTa)
      if (editedValues.Image instanceof File) {
        formDataToSend.append("files", editedValues.Image)
      }
      const userValue = JSON.parse(localStorage.getItem("authUser"))
      const token = userValue.token
      const decodedToken = jwtDecode(token)
      const { Username } = decodedToken
     
      const user = Username
      formDataToSend.append("user", user)

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/category/updateCategory/${selectedCategory.id}`,
        {
          method: "PUT",
          body: formDataToSend,
        }
      )
      if (response.status === 200) {
        console.log("Category updated successfully!")
        document.location.reload()
      }
    } catch (error) {
      console.error("Error updating category:", error)
    }
  }

  const deleteCategory = useCallback(async id => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/category/deleteCategory/${id}`,
        {
          method: "DELETE",
        }
      )

      if (response.ok) {
        message.success("Category deleted successfully!")
        document.location.reload()
      } else {
        console.log("Failed to delete category:", response.statusText)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }, [])

  useEffect(() => {
    fetchCategorys()
  }, [props])
  const fetchCategorys = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/category/getAllCategories`
      )

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const apiData = await response.json()


      if (apiData[0]) {
        const columnLabels = []

        const rowsData = apiData.map(item => {
          const imageFileName = Array.isArray(item.Image)
            ? item.Image[0]
            : item.Image
          const cleanedFileName = imageFileName.replace(/[\[\]"]/g, "")

          return {
            id: item.id,
            stringNameEn: item.NameEn,
            stringNameSi: item.NameSi,
            stringNameTa: item.NameTa,

            image: (
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/category/${cleanedFileName}`}
                alt=""
                style={{ maxWidth: "100%", maxHeight: "50px" }}
              />
            ),

            action: (
              <Space size="middle">
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedCategory(item)
                    toggleModal()
                  }}
                >
                  View/Edit
                </Button>
              </Space>
            ),
            actionDelete: (
              <Popconfirm
                title="Are you sure you want to delete this category?"
                onConfirm={() => deleteCategory(item.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            ),
          }
        })

        setData({
          columns: [
            ...columnLabels,
            {
              label: "Id",
              field: "id",
              sort: "asc",
              width: 50,
            },
            {
              label: "Name (English)",
              field: "stringNameEn",
              sort: "asc",
              width: 50,
            },

            {
              label: "Name (Sinhala)",
              field: "stringNameSi",
              sort: "asc",
              width: 150,
            },
            {
              label: "Name (Tamil)",
              field: "stringNameTa",
              sort: "asc",
              width: 150,
            },
            {
              label: "Image",
              field: "image",
              sort: "disabled",
              width: 100,
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 150,
            },
            {
              label: "Delete",
              field: "actionDelete",
              width: 50,
            },
          ],
          rows: rowsData,
        })
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {data.rows?.length > 0 ? (
                <MDBDataTable responsive bordered data={data} noBottomColumns />
              ) : (
                <div className="text-center">
                  <h3>No Categorys Found</h3>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Category Details</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="nameEn">Name (English)</Label>
            <Input
              type="text"
              id="nameEn"
              value={editedValues.NameEn}
              onChange={e => handleInputChange("NameEn", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="nameSi">Name (Sinhala)</Label>
            <Input
              type="text"
              id="nameSi"
              value={editedValues.NameSi}
              onChange={e => handleInputChange("NameSi", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="nameTa">Name (Tamil)</Label>
            <Input
              type="text"
              id="nameTa"
              value={editedValues.NameTa}
              onChange={e => handleInputChange("NameTa", e.target.value)}
            />
          </FormGroup>

          {/* Display image */}
          {editedValues.Image && typeof editedValues.Image === "string" && (
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/category/${editedValues.Image}`}
              alt=""
              style={{ maxWidth: "100%", maxHeight: "100px" }}
            />
          )}

          <FormGroup>
            <Label for="image">Image</Label>
            <Input
              type="file"
              id="image"
              onChange={e => handleInputChange("Image", e.target.files[0])}
            />
          </FormGroup>

          {/* Update button */}
          <Button color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(DataTableCategory)
