import React , {useEffect} from "react"

import { connect } from "react-redux";


import { setBreadcrumbItems } from "../../store/actions";
import DataTable from "../Customers/table"

const Dashboard1 = (props) => {

  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "customers", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Customers' , breadcrumbItems)
  },)


  return (
    <React.Fragment>

    

      <DataTable />
     

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard1);