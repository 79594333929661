import React, { useCallback, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { jwtDecode } from "jwt-decode"
import axios from "axios"

import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./datatables.scss"
import { Divider, Space, Tag, message, Popconfirm } from "antd"
import UpdateOrderStatusModal from "./updateModel"
import ViewModel from "./viewModel"

const DatatableTables = props => {
  const [showModel, setShowModel] = useState(false)
  const [viewModel, setViewModel] = useState(false)
  const [edit, setEdit] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [data, setData] = useState({
    columns: [],
    rows: [],
  })

  const daysOfWeek = [
    { options: [{ value: "Sunday", label: "Sunday", fieldId: "day" }] },
    { options: [{ value: "Monday", label: "Monday", fieldId: "day" }] },
    { options: [{ value: "Tuesday", label: "Tuesday", fieldId: "day" }] },
    { options: [{ value: "Wednesday", label: "Wednesday", fieldId: "day" }] },
    { options: [{ value: "Thursday", label: "Thursday", fieldId: "day" }] },
    { options: [{ value: "Friday", label: "Friday", fieldId: "day" }] },
    { options: [{ value: "Saturday", label: "Saturday", fieldId: "day" }] },
  ]

  const deleteOrder = useCallback(async id => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/orders/${id}`,
        {
          method: "DELETE",
        }
      )
     

      if (response.ok) {
        message.success("Order deleted successfully!")
        document.location.reload()
      } else {
        console.log("Failed to delete product:", response.statusText)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }, [])

  useEffect(() => {
    // Fetch data from API
    fetchOrders()
  }, [props])
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/orders`)

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const apiData = await response.json()
      const user = JSON.parse(localStorage.getItem("authUser"))
      const token = user.token
      const decodedToken = jwtDecode(token)
      const { Username } = decodedToken
     

      // Map keys to column labels
      if (apiData.orders[0]) {
        const columnLabels = []

        // Map items to rows
        const rowsData = apiData.orders?.map(item => {
          return {
            ...item,

            date: new Date(item.date).toLocaleDateString(), // Convert date to a readable format if needed
            products: item?.items,
            Status: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "left",
                  textAlign: "left", // Center align the contents
                }}
              >
                <Tag
                  color={
                    item.Status === "pending"
                      ? "processing"
                      : item.Status === "delivered"
                      ? "success"
                      : item.Status === "cancelled"
                      ? "error"
                      : item.Status === "shipped"
                      ? "orange"
                      : item.Status
                  }
                >
                  {item.Status}
                </Tag>
                <Button
                  size="sm"
                  style={{ margin: "10px" }}
                  color="primary"
                  onClick={() => {
                    setShowModel(true)
                    setSelectedOrder(item)
                  }}
                >
                  Update
                </Button>
              </div>
            ),
            action: (
              <Space size="middle">
                <Button
                  color="primary"
                  onClick={() => {
                    setViewModel(true)
                    setSelectedOrder(item)
                  }}
                >
                  View
                </Button>
              </Space>
            ),
            actionDelete: (
              <Popconfirm
                title="Are you sure you want to delete this product?"
                onConfirm={() => deleteOrder(item.OrderID)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            ),
          }
        })

        setData({
          columns: [
            ...columnLabels,
            {
              label: "Id",
              field: "OrderID",
              sort: "asc",
              width: 50,
            },
            {
              label: "Order Date",
              field: "OrderDate",
              sort: "asc",
              width: 50,
            },
            {
              label: "Total Amount",
              field: "TotalAmount",
              sort: "asc",
              width: 20,
            },
            {
              label: "UserName",
              field: "UserName",
              sort: "asc",
              width: 50,
            },
            {
              label: "Contact No",
              field: "ContactNo",
              sort: "asc",
              width: 50,
            },
            {
              label: "Shipping Address",
              field: "ShippingAddress",
              sort: "asc",
              width: 50,
            },
            {
              label: "Email",
              field: "Email",
              sort: "asc",
              width: 50,
            },
            {
              label: "Status",
              field: "Status",
              sort: "asc",
              width: 50,
              customBodyRender: (value, tableMeta) => {
                const item = tableMeta.rowData 

                return (
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tag
                        color={
                          value === "pending"
                            ? "processing"
                            : value === "delivered"
                            ? "success"
                            : value === "cancelled"
                            ? "error"
                            : value
                        }
                      >
                        {value}
                      </Tag>
                    </div>
                    <div style={{ marginTop: "10px", textAlign: "center" }}>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setShowModel(true)
                          setSelectedOrder(item)
                        }}
                      >
                        Update
                      </Button>
                    </div>
                  </React.Fragment>
                )
              },
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 150,
            },
            {
              label: "Delete",
              field: "actionDelete",
              width: 50,
            },
          ],
          rows: rowsData,
        })
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  return (
    <React.Fragment>
      <UpdateOrderStatusModal
        showModel={showModel}
        handleCloseModel={() => {
          setShowModel(false)
          fetchOrders()
        }}
        selectedOrder={selectedOrder}
      />
      <ViewModel
        showModel={viewModel}
        handleCloseModel={() => {
          setViewModel(false)
          fetchOrders()
        }}
        selectedOrder={selectedOrder}
        handleCloseEdit={() => {
          setEdit(!edit)
        }}
        edit={edit}
        setSelectedOrder={setSelectedOrder}
        setEdit={setEdit}
        products={selectedOrder?.items}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              
              {data.rows.length > 0 ? (
                <MDBDataTable responsive bordered data={data} noBottomColumns />
              ) : (
                <div className="text-center">
                  <h3>No Orders Found</h3>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(DatatableTables)
