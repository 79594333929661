import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import axios from "axios"

const CommissionDetailsModal = ({ isOpen, toggleModal, commissionData }) => {
  const [orderDetails, setOrderDetails] = useState([])

  useEffect(() => {
    if (commissionData) {
      fetchOrderDetails(commissionData.cus_id)
    }
  }, [commissionData])

  const fetchOrderDetails = async cus_id => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/customer/getAgentDetailsValues/${cus_id}`
      )

      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        )
      }

      const contentType = response.headers.get("content-type")
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Response is not JSON")
      }

      const commissionData = await response.json()
    

      if (commissionData && commissionData.orderDetails) {
        setOrderDetails(commissionData.orderDetails)
      } else {
        setOrderDetails([])
      }
    } catch (error) {
      console.error("Error fetching order details:", error)
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Agent Details</ModalHeader>
      <ModalBody>
        {Object.keys(orderDetails).length > 0 && (
          <div>
            <ul>
              {Object.keys(orderDetails).map((orderId, orderIdIndex) => (
                <li
                  key={orderIdIndex}
                  style={{
                    border: "2px solid blue",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <p>Order ID: {orderId}</p>
                  <p>Order Details:</p>
                  <ul>
                    {Object.keys(orderDetails[orderId]).map(
                      (orderDate, orderDateIndex) => (
                        <li key={orderDateIndex}>
                          <p>Order Date: {orderDate}</p>
                          <p>Product Details:</p>
                          <ul>
                            {Object.keys(orderDetails[orderId][orderDate]).map(
                              (productId, productIdIndex) => {

                                let productName = orderDetails[orderId][orderDate][
                                  productId
                                ][0].ProductNames
                                
                            
                                

                                if(productName && productName[0] === "{"){
                                  try{
                                    productName = JSON.parse(productName)["en"]
                                  }catch(err){
                                    console.log(err)
                                  }
                                }

                                return (
                                  
                                  <li key={productIdIndex}>
                                    <p>Product ID: {productId}</p>
                                    <p>Product Name: {productName}</p>
                                    <p>
                                      Quantity:{" "}
                                      {
                                        orderDetails[orderId][orderDate][
                                          productId
                                        ][0].Quantity[0]
                                      }
                                    </p>
                                    <p>
                                      Unit Price:{" "}
                                      {
                                        orderDetails[orderId][orderDate][
                                          productId
                                        ][0].Price[0]
                                      }
                                    </p>
                                    <p>
                                      Total Price:{" "}
                                      {
                                        orderDetails[orderId][orderDate][
                                          productId
                                        ][0].TotalAmount
                                      }
                                    </p>
                                    <p>
                                      Points:{" "}
                                      {
                                        orderDetails[orderId][orderDate][
                                          productId
                                        ][0].PointsTotal[0]
                                      }
                                    </p>
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default CommissionDetailsModal
