import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

const DataTable = props => {
  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [data4, setData4] = useState([])
  const [data5, setData5] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  useEffect(() => {
    fetchAllDataForTabs()
  }, [props])

  const fetchAllDataForTabs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/userLogs`)
      const data = await response.json()

  

      setData1(data.tab1)
      setData2(data.tab2)
      setData3(data.tab3)
      setData4(data.tab4)
      setData5(data.tab5)
    } catch (error) {
      console.error("Error fetching data for tabs:", error)
    }
  }

  const columns1 = [
    {
      label: "Order Id",
      field: "OrderID",
    },
    {
      label: "Updated By",
      field: "updated_by",
    },
    {
      label: "Updated Date",
      field: "updated_at",
    },
    {
      label: "Updated Note",
      field: "updated_note",
    },
  ]

  const columns2 = [
    {
      label: "Product ID",
      field: "ProductID",
    },
    {
      label: "Product Name",
      field: "Name",
      format: value => {
     
        try {
          const parsedValue = JSON.parse(value)
          return parsedValue.en || ""
        } catch (error) {
          console.error("Error parsing JSON:", error)
          return ""
        }
      },
    },
    {
      label: "Updated By",
      field: "updated_by",
    },
    {
      label: "Updated Date",
      field: "updated_at",
    },
    {
      label: "Updated Note",
      field: "updated_note",
    },
   
  ]

  const columns3 = [
    {
      label: "Customer ID",
      field: "cus_id",
    },
    {
      label: "Customer Name",
      field: "username",
    },
    {
      label: "Updated By",
      field: "updated_by",
    },
    {
      label: "Updated Date",
      field: "updated_at",
    },
    {
      label: "Updated Note",
      field: "updated_note",
    },
   
  ]

  const columns4 = [
    {
      label: "Category ID",
      field: "id",
    },
    {
      label: "Category Name",
      field: "NameEn",
    },
    {
      label: "Updated By",
      field: "updated_by",
    },
    {
      label: "Updated Date",
      field: "updated_at",
    },
    {
      label: "Updated Note",
      field: "updated_note",
    },
  ]

  const columns5 = [
    {
      label: "Group ID",
      field: "id",
    },
    {
      label: "Group Name",
      field: "NameEn",
    },
    {
      label: "Updated By",
      field: "updated_by",
    },
    {
      label: "Updated Date",
      field: "updated_at",
    },
    {
      label: "Updated Note",
      field: "updated_note",
    },
  ]

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  Order Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  Products Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => setActiveTab("3")}
                >
                  Customer Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => setActiveTab("4")}
                >
                  Category Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => setActiveTab("5")}
                >
                  Group Logs
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <MDBDataTable
                  responsive
                  bordered
                  data={{ columns: columns1, rows: data1 }}
                  noBottomColumns
                />
              </TabPane>
              <TabPane tabId="2">
                <MDBDataTable
                  responsive
                  bordered
                  data={{ columns: columns2, rows: data2 }}
                  noBottomColumns
                />
              </TabPane>
              <TabPane tabId="3">
                <MDBDataTable
                  responsive
                  bordered
                  data={{ columns: columns3, rows: data3 }}
                  noBottomColumns
                />
              </TabPane>
              <TabPane tabId="4">
                <MDBDataTable
                  responsive
                  bordered
                  data={{ columns: columns4, rows: data4 }}
                  noBottomColumns
                />
              </TabPane>
              <TabPane tabId="5">
                <MDBDataTable
                  responsive
                  bordered
                  data={{ columns: columns5, rows: data5 }}
                  noBottomColumns
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default connect(null, { setBreadcrumbItems })(DataTable)
