import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { jwtDecode } from "jwt-decode"
import { MDBDataTable } from "mdbreact"
import { Space, message, Popconfirm } from "antd"
import { toast } from 'react-toastify';

import {
  Row,
  Table,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
} from "reactstrap"
import axios from "axios"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./datatables.scss"
import { Modal } from "antd"
import UpdateCustomersStatusModal from "./updateModel"
import Papa from "papaparse"
import AgentDetailsModal from "./agentDetailsModal"

const DataTable = props => {
  const isClickable = false
  const [showModel, setShowModel] = useState(false)
  const [data, setData] = useState({
    columns: [],
    rows: [],
    agentColumns: [],
    agentRows: [],
  })
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [agentModalOpen, setAgentModalOpen] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [valueEntries, setValueEntries] = useState([])
  const [editingId, setEditingId] = useState(null)

  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)

  const [minPrice, setMinPrice] = useState("")
  const [maxPrice, setMaxPrice] = useState("")
  const [percentage, setPercentage] = useState("")
  const [orders, setOrders] = useState([])

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch orders 
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/orders`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setOrders(data.orders);
        console.log('Fetched orders: ', data.orders || data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  {
    orders &&
      console.log(orders)
  }

  const handleGenarateReport = () => {
    if (loading) {
      alert("Orders are still loading. Please wait.");
      return;
    }

    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }

    // Ensure orders is an array
    const validOrders = Array.isArray(orders) ? orders : [];

    // Filter orders based on the selected date range
    const filteredOrders = validOrders.filter(order => {
      const orderDate = new Date(order.OrderDate);
      return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
    });

    // Use data.rows for customers, sum total amount for each customer and include order dates
    const customerTotalAmounts = data.rows.map(customer => {
      // Match the orders for this customer based on email or mobile number
      const customerOrders = filteredOrders.filter(order =>
        order.Email === customer.email || order.ContactNo === customer.mobilenumber
      );

      // Calculate total amount for the customer
      const totalAmount = customerOrders.reduce((sum, order) => sum + parseFloat(order.TotalAmount), 0);

      // Concatenate all the order dates
      const orderDates = customerOrders.map(order => order.OrderDate).join(", ");

      // Return the report data for this customer
      return {
        "Customer ID": customer.cus_id,
        "User Name": customer.username,
        Email: customer.email,
        "Mobile Number": customer.mobilenumber,
        Address: customer.address,
        "Total Amount": totalAmount,
        "Order Dates": orderDates,
        "Total Points": customer.total_points,
        Commission: customer.commission,
        "Date Range": `${startDate} to ${endDate}`,
      };
    });

    setFilteredData(customerTotalAmounts);

    // Generate CSV
    const csvData = Papa.unparse(customerTotalAmounts);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Orders_Report.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };


  const exportToCSV = () => {
    const filteredData = data.rows.map(row => ({
      Id: row.cus_id,
      "User Name": row.username,
      Email: row.email,
      "Mobile Number": row.mobilenumber,
      Address: row.address,
      "Total Points": row.totalPoints,
      "Total Sales Amount": row.totalSalesAmount,
      Commission: row.commission,
    }))

    const csvData = Papa.unparse(filteredData)
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    a.download = "table-data.csv"
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  useEffect(() => {
    const fetchCommissionRates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/customer/getCommissionRates`
        )
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        const commisionData = await response.json()

        setValueEntries(commisionData)
      } catch (error) {
        console.error("Error fetching commission rates:", error)
      }
    }



    fetchCustomers()
    fetchCommissionRates()
  }, [])

  const handleRemoveEntry = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/commissionRates/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Fetch the updated list after removal
      fetchCommissionRates();
    } catch (error) {
      console.error('Error removing entry:', error);
    }
  };

  const handleEditEntry = async (id) => {
    try {
      const updatedEntry = {
        minPrice: minPrice,
        maxPrice: maxPrice,
        percentage: percentage,
      };

      console.log('handleEditEntry called with:', updatedEntry, id);


      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/commissionRates/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEntry),
      });

      if (response.status === 404) {
        console.error('Commission rate not found');
        return;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Response from server:', data);


      setEditingId(null);
      // fetchCommissionRates()
    } catch (error) {
      console.error('Error updating entry:', error);
    }
  };

  useEffect(() => {
    fetchCustomers()
  }, [props])

  const toggleAgentModal = () => {
    setAgentModalOpen(!agentModalOpen)
  }

  const handleAgentUsernameClick = agent => {
    setSelectedAgent(agent)
    toggleAgentModal()
  }

  const fetchCustomers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/customer/getAllCustomers`
      )
      const apiData = await response.json()

      if (apiData) {
        const commissionResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/customer/getCommissionRates`
        )
        const commissionData = await commissionResponse.json()


        const commonColumns = [
          {
            label: "Id",
            field: "cus_id",
            sort: "asc",
            width: 10,
          },
          {
            label: "User Name",
            field: "username",
            sort: "asc",
            width: 20,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 50,
          },
          {
            label: "Mobile Number",
            field: "mobilenumber",
            sort: "asc",
            width: 15,
          },
          {
            label: "Address",
            field: "address",
            sort: "asc",
            width: 50,
          },
          {
            label: "Is Agent",
            field: "checkbox",
            width: 5,
          },
        ]

        const agentColumns = [
          {
            label: "Total Points",
            field: "totalPoints",
            sort: "asc",
            width: 20,
          },
          {
            label: "Total Sales Amount",
            field: "totalSalesAmount",
            sort: "asc",
            width: 20,
          },
          {
            label: "Commission",
            field: "commission",
            sort: "asc",
            width: 20,
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 150,
          },
        ]

        const commonRows = apiData.map(item => ({
          ...item,
          customers: item?.items,
          checkbox: item.is_agent === 1,
        }))




        const agentRows = await Promise.all(
          apiData
            .filter(item => item.is_agent === 1)
            .map(async item => {
              try {
                const commissionResponse = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/customer/getAgentValues/${item.cus_id}`
                )
                const agentData = await commissionResponse.json()

                if (!agentData.orderDetails) {
                  return null
                }



                const handleCommissionPayment = async () => {
                  try {

                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/updateAgentCommission`, {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        customerId: item.cus_id,
                      })
                    });
                    if (response.ok) {
                      message.success("Commission Paid Successfully");
                      setTimeout(() => {
                        window.location.reload();
                      }, 2000);

                    } else {
                      console.error('Failed to update commission');
                    }
                  } catch (error) {
                    console.error('Error updating commission:', error);
                  }
                };



                return {
                  ...item,
                  totalPoints: agentData.orderDetails[0].TotalPoints,
                  totalSalesAmount: agentData.orderDetails[0].TotalAmount,
                  commission: agentData.orderDetails[0].Commission,

                  action: (
                    <Space size="middle">
                      <Button
                        color="primary"
                        onClick={handleCommissionPayment}
                      >
                        Pay
                      </Button>
                    </Space>
                  ),
                }
              } catch (error) {
                console.error("Error fetching commission data:", error)
                return null
              }
            })
        )
        const filledAgentRows = agentRows.map((row, index) => {
          if (row === null) {
            return commonRows[index]
          }
          return row
        })

        setData({
          columns: commonColumns,
          rows: commonRows,
          agentColumns,
          agentRows: filledAgentRows.filter(row => row !== null),
        })
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const handleAddValues = async (
    minAmount,
    maxAmount,
    commissionPercentage
  ) => {
    try {
      const userValue = JSON.parse(localStorage.getItem("authUser"))
      const token = userValue.token
      const decodedToken = jwtDecode(token)
      const { Username } = decodedToken
      const user = Username

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/customer/saveCommissionRate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ minAmount, maxAmount, commissionPercentage }),
        }
      )

      if (response.ok) {
        fetchCustomers()
        window.location.reload()
        console.log("Values added successfully")
      } else {
        console.error("Failed to add values")
      }
    } catch (error) {
      console.error("Error adding values:", error)
    }
  }

  const handleCheckboxChange = index => {
    const updatedRows = [...data.rows]
    const updatedCustomer = updatedRows[index]
    updatedCustomer.checkbox = !updatedCustomer.checkbox

    setData(prevState => ({
      ...prevState,
      rows: updatedRows,
    }))
    if (!updatedCustomer.checkbox) {
      // setConfirmModalVisible(true)
    }
    if (updatedCustomer.checkbox) {
      setSelectedCustomers([...selectedCustomers, updatedCustomer])
      setConfirmModalVisible(true)
    } else {
      setSelectedCustomers(
        selectedCustomers.filter(
          customer => customer.cus_id !== updatedCustomer.cus_id
        )
      )
    }
    if (!updatedCustomer.checkbox && updatedCustomer.is_agent) {
      const confirmRemoveAgent = window.confirm(
        "Are you sure you want to remove them as an agent?"
      )
      if (!confirmRemoveAgent) {
        updatedCustomer.checkbox = true
        setData(prevState => ({
          ...prevState,
          rows: updatedRows,
        }))
        setSelectedCustomers(
          selectedCustomers.filter(
            customer => customer.cus_id !== updatedCustomer.cus_id
          )
        )
        return
      } else {
        const userValue = JSON.parse(localStorage.getItem("authUser"))
        const token = userValue.token
        const decodedToken = jwtDecode(token)
        const { Username } = decodedToken
        const user = Username

        fetch(
          `${process.env.REACT_APP_BASE_URL}/customer/updateCustomersAsNonAgents`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customerIds: [updatedCustomer.cus_id],
              user,
            }),
          }
        )
          .then(response => {
            if (response.ok) {
              window.location.reload()
              console.log("Customer updated successfully as non-agent")
            } else {
              console.error("Failed to update customer as non-agent")
            }
          })
          .catch(error => {
            console.error("Error updating customer as non-agent:", error)
          })
      }
    }
  }

  const handleConfirmOk = async () => {
    try {
      const unselectedCustomers = data.rows.filter(row => row.checkbox)
      const customerIds = unselectedCustomers.map(customer => customer.cus_id)
      const userValue = JSON.parse(localStorage.getItem("authUser"))
      const token = userValue.token
      const decodedToken = jwtDecode(token)
      const { Username } = decodedToken

      const user = Username

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/customer/updateCustomersAsAgents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customerIds, user }),
        }
      )

      if (!response.ok) {
        throw new Error("Failed to update customers")
      }
      window.location.reload()
      console.log("Customers updated successfully")
      setConfirmModalVisible(false)
    } catch (error) {
      console.error("Error updating customers:", error)
    }
  }

  const handleFromDateChange = event => {
    const selectedDate = event.target.value

    setStartDate(selectedDate)
  }

  const handleToDateChange = event => {
    const selectedDate = event.target.value

    setEndDate(selectedDate)
  }

  const handleConfirmCancel = () => {
    setConfirmModalVisible(false)
    const updatedRows = data.rows.map(row => ({
      ...row,
      checkbox: false,
    }))
    setData(prevState => ({
      ...prevState,
      rows: updatedRows,
    }))
    setSelectedCustomers([])
  }

  return (
    <React.Fragment>
      <UpdateCustomersStatusModal
        showModel={showModel}
        handleCloseModel={() => {
          setShowModel(false)
          fetchCustomers()
        }}
        selectedCustomers={selectedCustomers}
      />

      <Modal
        title="Confirm"
        open={confirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
      >
        Are you sure you want to update this customer as an agent?
      </Modal>

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    All Customers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    Agent Customers
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {data.rows.length > 0 ? (
                    <React.Fragment>
                      <MDBDataTable
                        responsive
                        bordered
                        data={{
                          ...data,
                          rows: data.rows.map((row, index) => ({
                            ...row,
                            checkbox: (
                              <input
                                type="checkbox"
                                checked={row.checkbox}
                                onChange={() => handleCheckboxChange(index)}
                              />
                            ),
                          })),
                        }}
                        noBottomColumns
                      />

                      <br />
                      <Button onClick={exportToCSV}>Export to CSV</Button>
                      <br />
                    </React.Fragment>
                  ) : (
                    <div className="text-center">
                      <h3>No Customers Found</h3>
                    </div>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  <div className="mt-4" style={{ border: "groove" }}>
                    <h5>Agent Customer Report </h5>
                    <br />

                    <Row>
                      <Col md={3}>
                        <label
                          style={{ paddingRight: "5px" }}
                          htmlFor="fromDate"
                        >
                          From:
                        </label>
                        <input
                          style={{ paddingLeft: "5px" }}
                          type="date"
                          id="fromDate"
                          selected={startDate}
                          onChange={handleFromDateChange}
                          dateformat="yyyy-MM-dd"
                        />
                      </Col>
                      <Col md={3}>
                        <label style={{ paddingRight: "5px" }} htmlFor="toDate">
                          To:
                        </label>
                        <input
                          style={{ paddingLeft: "5px" }}
                          type="date"
                          id="toDate"
                          selected={endDate}
                          onChange={handleToDateChange}
                          dateformat="yyyy-MM-dd"
                        />
                      </Col>
                      <Col md={2}>
                        {/* <Button
                          className="mb-3"
                          color="primary"
                          onClick={handleGenarateReport}
                        >
                          Generate Report
                        </Button> */}
                        <Button
                          className="mb-3"
                          color="primary"
                          onClick={handleGenarateReport}
                          disabled={loading} // Disable button when loading
                        >
                          {loading ? "Loading Orders..." : "Generate Report"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Row className="mt-3">
                    <Col md={2}>
                      <div>Min Price</div>
                      <Input
                        type="text"
                        value={minPrice}
                        onChange={e => setMinPrice(e.target.value)}
                        placeholder="Enter Min"
                      />
                    </Col>
                    ---
                    <Col md={2}>
                      <div>Max Price</div>
                      <Input
                        type="text"
                        value={maxPrice}
                        onChange={e => setMaxPrice(e.target.value)}
                        placeholder="Enter Max"
                      />
                    </Col>
                    <Col md={2}>
                      <div>Percentage</div>
                      <Input
                        type="text"
                        value={percentage}
                        onChange={e => setPercentage(e.target.value)}
                        placeholder="%"
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        className="mt-3"
                        color="primary"
                        onClick={() =>
                          handleAddValues(minPrice, maxPrice, percentage)
                        }
                      >
                        Add Values
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={6}>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Min Price</th>
                            <th>Max Price</th>
                            <th>Percentage</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {valueEntries.map((entry) => (
                            <tr key={entry.id}>
                              <th scope="row">{entry.id}</th>
                              <td>
                                {editingId === entry.id ? (
                                  <Input
                                    type="number"
                                    value={minPrice}
                                    onChange={(e) => setMinPrice(e.target.value)}
                                  />
                                ) : (
                                  entry.min_amount
                                )}
                              </td>
                              <td>
                                {editingId === entry.id ? (
                                  <Input
                                    type="number"
                                    value={maxPrice}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                  />
                                ) : (
                                  entry.max_amount
                                )}
                              </td>
                              <td>
                                {editingId === entry.id ? (
                                  <Input
                                    type="number"
                                    value={percentage}
                                    onChange={(e) => setPercentage(e.target.value)}
                                  />
                                ) : (
                                  `${entry.commission_percentage}%`
                                )}
                              </td>
                              <td>
                                {editingId === entry.id ? (
                                  <>
                                    <Button
                                      color="success"
                                      onClick={() => handleEditEntry(entry.id)}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="secondary"
                                      onClick={() => setEditingId(null)}
                                      style={{ marginLeft: '5px' }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      // Set initial values when editing starts
                                      setMinPrice(entry.min_amount);
                                      setMaxPrice(entry.max_amount);
                                      setPercentage(entry.commission_percentage);
                                      setEditingId(entry.id);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                                <Button
                                  color="danger"
                                  onClick={() => handleRemoveEntry(entry.id)}
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <br />
                  {data.agentRows.length > 0 ? (
                    <>
                      <MDBDataTable
                        responsive
                        bordered
                        data={{
                          columns: [
                            ...data.columns.filter(
                              column => column.field !== "checkbox"
                            ),
                            ...data.agentColumns,
                          ],
                          rows: data.agentRows.map((row, index) => ({
                            ...row,
                            username: (
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                  border: "1px solid",
                                  padding: 10,
                                }}
                                onClick={() => handleAgentUsernameClick(row)}
                              >
                                {row.username}
                              </span>
                            ),
                          })),
                        }}
                        noBottomColumns
                      />
                    </>
                  ) : (
                    <div className="text-center">
                      <h3>No Agent Customers Found</h3>
                    </div>
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <AgentDetailsModal
        isOpen={agentModalOpen}
        toggleModal={toggleAgentModal}
        commissionData={selectedAgent}
      />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(DataTable)
