import React, { useEffect, useRef, useState } from "react"
import Input from "./Input"
import { jwtDecode } from "jwt-decode"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
} from "reactstrap"
import { message } from "antd"

const AddProduct = ({
  setAddProduct,
  addProduct,
  actionType,
  selectedProduct,
}) => {
  let [product, setProduct] = useState({
    name_en: "",
    name_si: "",
    name_ta: "",
    description_en: "",
    description_si: "",
    description_ta: "",
    retailPrice: "",
    wholesalePrice: "",
    priority: "",
    maxLimit: "",
    youtubeLink: "",
    quantity: "",
    unit: "",
    image: null,
    category_id: 0,
    group_id: 0,
    inStock: 0,
    points: "",
  })
  const [formErrors, setFormErrors] = useState({})
  const [categories, setCategories] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [selectedGroup, setSelectedGroup] = useState({})
  const [inStockAvailble, setInStock] = useState(false)

  const [selectImage, setSelectImage] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const token = user.token
    const decodedToken = jwtDecode(token)
    const { Username } = decodedToken
   
    if (selectedProduct !== undefined && selectedProduct !== null) {
      if (Object.keys(selectedProduct)?.length > 0) {
        setProduct({
          name_en:
            selectedProduct.Name[0] === "{"
              ? JSON.parse(selectedProduct.Name).en
              : selectedProduct.Name,
          name_si:
            selectedProduct.Name[0] === "{"
              ? JSON.parse(selectedProduct.Name).si
              : selectedProduct.Name,
          name_ta:
            selectedProduct.Name[0] === "{"
              ? JSON.parse(selectedProduct.Name).ta
              : selectedProduct.Name,
          description_en:
            selectedProduct.Description === null
              ? ""
              : selectedProduct.Description[0] === "{"
              ? JSON.parse(selectedProduct.Description).en
              : selectedProduct.Description,
          description_si:
            selectedProduct.Description === null
              ? ""
              : selectedProduct.Description[0] === "{"
              ? JSON.parse(selectedProduct.Description).si
              : selectedProduct.Description,
          description_ta:
            selectedProduct.Description === null
              ? ""
              : selectedProduct.Description[0] === "{"
              ? JSON.parse(selectedProduct.Description).ta
              : selectedProduct.Description,
          retailPrice: selectedProduct.RetailPrice,
          wholesalePrice: selectedProduct.WholesalePrice,
          priority: selectedProduct.priority,
          maxLimit: selectedProduct.MaxLimit,
          youtubeLink: selectedProduct.youtubeLink,
          quantity: selectedProduct.WholesaleQty,
          unit: selectedProduct.Unit,
          image: selectedProduct.Image
            ? JSON.parse(selectedProduct.Image)[0]
            : null,
          category_id: selectedProduct.category_id,
          group_id: selectedProduct.group_id,
          inStock: selectedProduct.inStock,
          points: selectedProduct.points,
        })
        setSelectImage(
          selectedProduct.Image ? JSON.parse(selectedProduct.Image)[0] : null
        )
        setInStock(selectedProduct.inStock === 1 ? true : false)
      }
    }
  }, [selectedProduct])
  const handleChange = e => {
    const { name, value, type, checked } = e.target

    if (type === "checkbox") {
      setInStock(checked ? 1 : 0)
    }
    if (name === "image") {
      setSelectImage(e.target.files[0])
    } else {
      setProduct({
        ...product,
        [name]: value,
      })
    }
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/category/getAllCategories`
        )
        if (response.ok) {
          const data = await response.json()
          setCategories(data)
        } else {
          throw new Error("Failed to fetch categories")
        }
      } catch (error) {
        console.error("Error fetching categories:", error)
      }
    }

    fetchCategories()
  }, [])

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/group/getAllGroups`
        )
        if (response.ok) {
          const data = await response.json()
          setGroups(data)
        } else {
          throw new Error("Failed to fetch groups")
        }
      } catch (error) {
        console.error("Error fetching groups:", error)
      }
    }

    fetchGroups()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    let formData = new FormData()
    formData.append(
      "name",
      JSON.stringify({
        en: product.name_en,
        si: product.name_si,
        ta: product.name_ta,
      })
    )
    formData.append(
      "desc",
      JSON.stringify({
        en: product.description_en,
        si: product.description_si,
        ta: product.description_ta,
      })
    )
    formData.append("retailPrice", product.retailPrice)
    formData.append("wholesalePrice", product.wholesalePrice)
    formData.append("priority", product.priority)
    formData.append("maxLimit", product.maxLimit)
    formData.append("youtubeLink", product.youtubeLink)
    formData.append("quantity", product.quantity)
    formData.append("inStock", inStockAvailble)
    formData.append("points", product.points)
    formData.append(
      "category_id",
      product.category_id !== undefined ? product.category_id : "" || ""
    )
    formData.append(
      "group_id",
      product.group_id !== undefined ? product.group_id : "" || ""
    )
    formData.append("unit", product.unit)
    formData.append("image", selectImage)

    try {
      if (actionType === "Add") {
        let formData = new FormData()
        formData.append(
          "name",
          JSON.stringify({
            en: product.name_en,
            si: product.name_si,
            ta: product.name_ta,
          })
        )
        formData.append(
          "desc",
          JSON.stringify({
            en: product.description_en,
            si: product.description_si,
            ta: product.description_ta,
          })
        )
        formData.append("retailPrice", product.retailPrice)
        formData.append("wholesalePrice", product.wholesalePrice)
        formData.append("priority", product.priority)
        formData.append("maxLimit", product.maxLimit)
        formData.append("youtubeLink", product.youtubeLink)
        formData.append("quantity", product.quantity)
        formData.append("inStock", inStockAvailble)
        formData.append("points", product.points)
        formData.append(
          "category_id",
          product.category_id !== undefined ? product.category_id : "" || ""
        )
        formData.append(
          "group_id",
          product.group_id !== undefined ? product.group_id : "" || ""
        )
        formData.append("unit", product.unit)
        formData.append("image", selectImage)

        const userValue = JSON.parse(localStorage.getItem("authUser"))
        const token = userValue.token
        const decodedToken = jwtDecode(token)
        const { Username } = decodedToken
       
        const user = Username
        formData.append("user", user)

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/products/add`,
          {
            method: "POST",
            body: formData,
          }
        )

        if (response.ok) {
          message.success("Product Added Successfully")

          setAddProduct(false)
          window.location.reload()
        } else {
          console.log("Failed to add product:", response.statusText)
        }
      } else {
        let formData1 = new FormData()
        formData1.append(
          "name",
          JSON.stringify({
            en: product.name_en,
            si: product.name_si,
            ta: product.name_ta,
          })
        )
        formData1.append(
          "desc",
          JSON.stringify({
            en: product.description_en,
            si: product.description_si,
            ta: product.description_ta,
          })
        )
        formData1.append("retailPrice", product.retailPrice)
        formData1.append("wholesalePrice", product.wholesalePrice)
        formData1.append("priority", product.priority)
        formData1.append("maxLimit", product.maxLimit)
        formData1.append("youtubeLink", product.youtubeLink)
        formData1.append("quantity", product.quantity)
        formData1.append("inStock", inStockAvailble)
        formData1.append("points", product.points)
        formData1.append(
          "category_id",
          product.category_id !== undefined ? product.category_id : "" || ""
        )
        formData1.append(
          "group_id",
          product.group_id !== undefined ? product.group_id : "" || ""
        )
        formData1.append("unit", product.unit)
        formData1.append("image", selectImage)

        const userValue = JSON.parse(localStorage.getItem("authUser"))
        const token = userValue.token
        const decodedToken = jwtDecode(token)
        const { Username } = decodedToken
        
        const user = Username
        formData1.append("user", user)

        const response1 = await fetch(
          `${process.env.REACT_APP_BASE_URL}/products/edit/${selectedProduct.ProductID}`,
          {
            method: "PUT",
            body: formData1,
          }
        )

        if (response1.ok) {
          message.success("Product Updated Successfully")
          setAddProduct(false)
          window.location.reload()
        } else {
          console.log("Failed to update product:", response1.statusText)
        }
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }
  const {
    name_en,
    name_si,
    name_ta,
    retailPrice,
    wholesalePrice,
    priority,
    maxLimit,
    youtubeLink,
    description_en,
    description_si,
    description_ta,
    quantity,
    image,
    unit,
    category_id,
    group_id,
    inStock,
    points,
  } = product
  return (
    <Modal
      isOpen={addProduct}
      toggle={() => setAddProduct(false)}
      style={{
        maxWidth: "1000px",
      }}
    >
      <ModalHeader toggle={() => setAddProduct(false)}>
        {actionType} Product
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col md={4}>
              <Input
                label="Name(English)"
                name="name_en"
                type="text"
                placeholder="Product name in English"
                required={true}
                value={name_en}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Name(Sinhala)"
                name="name_si"
                type="text"
                placeholder="Product name in Sinhala"
                required={true}
                value={name_si}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Name(Tamil)"
                name="name_ta"
                type="text"
                placeholder="Product name in Tamil"
                required={true}
                value={name_ta}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Input
                label="Priority"
                name="priority"
                type="number"
                placeholder="Priority"
                required={false}
                value={priority}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Quantity"
                name="quantity"
                type="number"
                placeholder="Quantity"
                required={true}
                value={quantity}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Unit"
                name="unit"
                type="text"
                placeholder="Unit"
                required={true}
                value={unit}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Input
                label={"image"}
                name="image"
                type="file"
                required={true}
                value={image}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Retail Price"
                name="retailPrice"
                type="number"
                placeholder="Retail price"
                required={true}
                value={retailPrice}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Wholesale Price"
                name="wholesalePrice"
                type="number"
                placeholder="Wholesale price"
                required={true}
                value={wholesalePrice}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Input
                label="Description(English)"
                name="description_en"
                type="textarea"
                placeholder="Description(English)"
                required={true}
                value={description_en}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Description(Sinhala)"
                name="description_si"
                type="textarea"
                placeholder="Description(Sinhala)"
                required={true}
                value={description_si}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Description(Tamil)"
                name="description_ta"
                type="textarea"
                placeholder="Description(Tamil)"
                required={true}
                value={description_ta}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Input
                label="maxLimit"
                name="maxLimit"
                type="number"
                placeholder="maxLimit"
                required={false}
                value={maxLimit}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Input
                label="Youtube Link"
                name="youtubeLink"
                type="text"
                placeholder="Youtube Link"
                required={false}
                value={youtubeLink}
                onChange={handleChange}
              />
            </Col>

            <Col md={4}>
              <Input
                label="Points"
                name="points"
                type="number"
                placeholder="Points"
                required={true}
                value={points}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlFor="category">Category</label>
              <select
                style={{ width: "100%", padding: "10px" }}
                id="category"
                name="category"
                value={category_id || null}
                onChange={e => {
                  setProduct({
                    ...product,
                    category_id: parseInt(e.target.value, 10) || null,
                  })
                  setSelectedCategory(
                    categories.find(
                      category => category.id === parseInt(e.target.value, 10)
                    ) || {}
                  )
                }}
              >
                <option value="" selected={category_id === null}>
                  Select category
                </option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {`${category.NameEn} | ${category.NameSi}`}
                  </option>
                ))}
              </select>
            </Col>

            <Col md={4}>
              <label htmlFor="group">Group</label>
              <select
                style={{ width: "100%", padding: "10px" }}
                id="group"
                name="group"
                value={group_id || null}
                onChange={e => {
                  setProduct({
                    ...product,
                    group_id: parseInt(e.target.value, 10) || null,
                  })
                  setSelectedGroup(
                    groups.find(
                      group => group.id === parseInt(e.target.value, 10)
                    ) || {}
                  )
                }}
              >
                <option value="" selected={group_id === null}>
                  Select group
                </option>
                {groups.map(group => (
                  <option key={group.id} value={group.id}>
                    {`${group.NameEn} | ${group.NameSi}`}
                  </option>
                ))}
              </select>
            </Col>

            <Col md={4}>
              <label style={{ paddingRight: "14px" }} htmlFor="inStock">
                In Stock
              </label>
              <input
                type="checkbox"
                id="inStock"
                name="inStock"
                checked={inStockAvailble}
                onChange={e => {
                  handleChange(e)
                }}
              />
            </Col>
          </Row>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          type="submit"
          onClick={e => {
            handleSubmit(e)
          }}
        >
          Submit
        </Button>
        <Button color="secondary" onClick={() => setAddProduct(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddProduct
