import React, { useEffect } from "react"
import { connect } from "react-redux"


import { setBreadcrumbItems } from "../../store/actions"
import Datatable from "./table"

const UserLogs = props => {
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "User Logs", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("User Logs", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <Datatable />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserLogs)
