import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Button } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"

const SMS = props => {
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "SMS", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("SMS", breadcrumbItems)
  }, [])

  const handleSMSGatewayClick = () => {
    window.open("http://sms.localseo.lk", "_blank")
  }



  const handleSubmitForm = () => {
    const formData = {
      first_name: "Pavan",
      last_name: "Welihinda",
      email: "customer_email@email.com",
      contact_number: "0773606370",
      address_line_one: "46/46, Green Lanka Building",
      process_currency: "LKR",
      payment: "",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/payment/paymentInit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        return response.text();

      })
      .then((html) => {
        // Handle the successful response from the server
        console.log("Form data submitted successfully");
        // Create and append script element if needed
        const script = document.createElement("script");
        // script.innerHTML = html;
        document.body.appendChild(script);
        // window.location.href = "https://stagingxpay.info/index.php?route=checkout/billing";
      })
      .catch((error) => {
        // Handle errors during form submission
        console.error("Error submitting form:", error);
      });
    }    

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "15vh", // This makes the container take up the full viewport height
      }}
    >
      {/* Display the button labeled "Payment Gateway" */}
      <Button
        color="primary"
        onClick={handleSMSGatewayClick}
        style={{
          width: "200px", // Set the width
          height: "50px", // Set the height
          fontSize: "18px", // Set the font size
        }}
      > 
        SMS Gateway
      </Button>

      <Button
        color="success"
        onClick={handleSubmitForm}
        style={{
          width: "200px", // Set the width
          height: "50px", // Set the height
          fontSize: "18px", // Set the font size
        }}
      > 
        Submit Form
      </Button>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(SMS)
