import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { message } from "antd"
import DataTableGroup from "../Group/table"
import { jwtDecode } from "jwt-decode"

const Group = props => {
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "Group", link: "#" },
  ]

  const [groupNames, setGroupNames] = useState({
    english: "",
    sinhala: "",
    tamil: "",
  })

  const fileInputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    props.setBreadcrumbItems("Group", breadcrumbItems)
  }, [])

  const handleGroupNameChange = (language, value) => {
    setGroupNames(prevNames => ({
      ...prevNames,
      [language]: value,
    }))
  }

  const handleFileInputChange = event => {
    setSelectedFile(event.target.files[0])
  }

  const handleAddGroup = async () => {
    const formData = new FormData()
    formData.append("files", selectedFile)
    formData.append("groupNameEnglish", groupNames.english)
    formData.append("groupNameSinhala", groupNames.sinhala)
    formData.append("groupNameTamil", groupNames.tamil)

    const user = JSON.parse(localStorage.getItem("authUser"))
    const token = user.token
    const decodedToken = jwtDecode(token)
    const { Username } = decodedToken

    formData.append("user", Username)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/group/add`,
        {
          method: "POST",
          body: formData,
        }
      )
    
      if (response.ok) {
        message.success("Group Added Successfully")
        window.location.reload()
        setGroupNames({
          english: "",
          sinhala: "",
          tamil: "",
        })
        if (fileInputRef.current) {
          fileInputRef.current.value = ""
        }
        setSelectedFile(null)
        window.location.reload()
      } else {
        console.error("Failed to save group data")
      }
    } catch (error) {
      console.error(
        "Error occurred during file upload or group data save:",
        error
      )
    }
  }

  return (
    <>
      <div className="p-4">
        {/* Form to add new group */}
        <div
          className="flex flex-row mx-auto"
          style={{ display: "flex", columnGap: "50px" }}
        >
          {/* English group Name */}
          <FormGroup className="col-span-1" style={{ width: "20%" }}>
            <Label for="groupNameEnglish">Group Name (English)</Label>
            <Input
              type="text"
              name="groupNameEnglish"
              id="groupNameEnglish"
              placeholder="Enter group name in English"
              value={groupNames.english}
              onChange={e => handleGroupNameChange("english", e.target.value)}
              className="w-16 px-2 py-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </FormGroup>

          {/* Sinhala group Name */}
          <FormGroup className="col-span-1" style={{ width: "20%" }}>
            <Label for="groupNameSinhala">Group Name (Sinhala)</Label>
            <Input
              type="text"
              name="groupNameSinhala"
              id="groupNameSinhala"
              placeholder="Enter group name in Sinhala"
              value={groupNames.sinhala}
              onChange={e => handleGroupNameChange("sinhala", e.target.value)}
              className="w-16 px-2 py-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </FormGroup>

          {/* Tamil group Name */}
          <FormGroup className="col-span-1" style={{ width: "20%" }}>
            <Label for="groupNameTamil">Group Name (Tamil)</Label>
            <Input
              type="text"
              name="groupNameTamil"
              id="groupNameTamil"
              placeholder="Enter group name in Tamil"
              value={groupNames.tamil}
              onChange={e => handleGroupNameChange("tamil", e.target.value)}
              className="w-16 px-2 py-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </FormGroup>
        </div>

        <br></br>

        <FormGroup>
          <Label style={{ paddingRight: "14px" }} htmlFor="groupImage">
            Upload Image
          </Label>
          <Input
            type="file"
            name="groupImage"
            id="groupImage"
            onChange={handleFileInputChange}
            ref={fileInputRef}
          />
        </FormGroup>

        <br></br>
        <Button color="success" onClick={handleAddGroup}>
          Add Group
        </Button>
      </div>
      <DataTableGroup />
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Group)
