import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
const DropDown = ({ products, selectedOrder, setSelectedOrder }) => {
    console.log(products);
  let [filteredProducts,setFilter] = useState([]);
  let [refproducts,setRef] = useState([]);
  const fetchProducts = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },

    }).then(async (data) => {
        let products = await data.json();
       let filteredProducts = products.filter((product) => {
            return !selectedOrder.items.some(
              (item) => item.ProductID === product.ProductID
            );
          });
          setFilter(filteredProducts)
          setRef(products)
        
    }).catch((err) => {
        console.log(err);
    })
   
}
  
useEffect(() => {
    fetchProducts();

},[selectedOrder])

  const handleChange = (event) => {
    const selectedProduct = refproducts.find(
      (product) => product.ProductID === Number(event.target.value)
    );

    const newItem = {
      ProductName: selectedProduct.Name,
      ProductID: selectedProduct.ProductID,
      Quantity: 1,
      Price: selectedProduct.RetailPrice,
      Subtotal: selectedProduct.RetailPrice,
      WholesaleQty: selectedProduct.WholesaleQty,
    };

    setSelectedOrder((prevOrder) => {
      const updatedItems = [...prevOrder.items, newItem];
      return { ...prevOrder, items: updatedItems };
    });
  };

  return (
    <div className="relative w-full lg:max-w-lg">
      <div style={{ display: "flex", justifyContent: "start", items: "start" }}>
        <h5>+ Add Items</h5>
      </div>

      <div className="overflow-y-auto max-h-60">
        <select
         style={{
            width:"100px"
         }}
          onChange={handleChange}
          className="w-full bg-[#111827] p-1 text-gray-500 h-full bg-transparent border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
        >
          {filteredProducts?.map((p) => (
            <option key={p.ProductID} value={p.ProductID}>
              {p.Name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropDown;
