import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { message } from "antd"
import DataTableCategory from "../Category/table"
import { jwtDecode } from "jwt-decode"

const Category = props => {
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "category", link: "#" },
  ]

  const [userCategories, setUserCategories] = useState([])
  const [categoryNames, setCategoryNames] = useState({
    english: "",
    sinhala: "",
    tamil: "",
  })

  const fileInputRef = useRef(null)

  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    props.setBreadcrumbItems("Category", breadcrumbItems)
  }, [])

  const handleCategoryNameChange = (language, value) => {
    setCategoryNames(prevNames => ({
      ...prevNames,
      [language]: value,
    }))
  }

  const handleFileInputChange = event => {
    setSelectedFile(event.target.files[0])
  }

  const handleAddCategory = async () => {
    const formData = new FormData()
    formData.append("files", selectedFile)
    formData.append("categoryNameEnglish", categoryNames.english)
    formData.append("categoryNameSinhala", categoryNames.sinhala)
    formData.append("categoryNameTamil", categoryNames.tamil)

    const user = JSON.parse(localStorage.getItem("authUser"))
    const token = user.token
    const decodedToken = jwtDecode(token)
    const { Username } = decodedToken
    formData.append("user", Username)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/category/add`,
        {
          method: "POST",
          body: formData,
        }
      )
    
      if (response.ok) {
        message.success("Category Added Successfully")
        document.location.reload()
        setCategoryNames({
          english: "",
          sinhala: "",
          tamil: "",
        })
        if (fileInputRef.current) {
          fileInputRef.current.value = ""
        }
        setSelectedFile(null)
        window.location.reload()
      } else {
        console.error("Failed to save category data")
      }
    } catch (error) {
      console.error(
        "Error occurred during file upload or category data save:",
        error
      )
    }
  }

  return (
    <>
      <div className="p-4">
      
        <div
          className="flex flex-row mx-auto"
          style={{ display: "flex", columnGap: "50px" }}
        >
      
          <FormGroup className="col-span-1" style={{ width: "20%" }}>
            <Label for="categoryNameEnglish">Category Name (English)</Label>
            <Input
              type="text"
              name="categoryNameEnglish"
              id="categoryNameEnglish"
              placeholder="Enter category name in English"
              value={categoryNames.english}
              onChange={e =>
                handleCategoryNameChange("english", e.target.value)
              }
              className="w-16 px-2 py-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </FormGroup>

        
          <FormGroup className="col-span-1" style={{ width: "20%" }}>
            <Label for="categoryNameSinhala">Category Name (Sinhala)</Label>
            <Input
              type="text"
              name="categoryNameSinhala"
              id="categoryNameSinhala"
              placeholder="Enter category name in Sinhala"
              value={categoryNames.sinhala}
              onChange={e =>
                handleCategoryNameChange("sinhala", e.target.value)
              }
              className="w-16 px-2 py-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </FormGroup>

         
          <FormGroup className="col-span-1" style={{ width: "20%" }}>
            <Label for="categoryNameTamil">Category Name (Tamil)</Label>
            <Input
              type="text"
              name="categoryNameTamil"
              id="categoryNameTamil"
              placeholder="Enter category name in Tamil"
              value={categoryNames.tamil}
              onChange={e => handleCategoryNameChange("tamil", e.target.value)}
              className="w-16 px-2 py-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </FormGroup>
        </div>
        <br></br>
        <br></br>
        <FormGroup>
          <Label style={{ paddingRight: "14px" }} htmlFor="categoryIcon">
            Upload Icon
          </Label>
          <Input
            type="file"
            name="categoryIcon"
            id="categoryIcon"
            onChange={handleFileInputChange}
            ref={fileInputRef}
          />
        </FormGroup>
        <br></br>
        <Button color="success" onClick={handleAddCategory}>
          Add Category
        </Button>
      </div>
      <DataTableCategory />
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Category)
