import React, { useCallback, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button, Label } from "reactstrap"
import axios from "axios"
import { Space, message, Popconfirm } from "antd";
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./datatables.scss"
import { Link } from "react-router-dom"
import AddProduct from "./addProduct"
import { isObject } from "lodash"
const DatatableTables = props => {
  const [addProduct, setAddProduct] = useState(false)
  const [actionType, setActionType] = useState("")
  const [selectedProduct, setSelectedProduct] = useState()
  

  const [data, setData] = useState({
    columns: [],
    rows: [],
  }) 

  const deleteProduct = useCallback(async id => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/products/${id}`,
        {
          method: "DELETE",
        }
      )

      if (response.ok) {
        message.success("Product deleted successfully!")
        document.location.reload()
      } else {
        console.log("Failed to delete product:", response.statusText)
      }
    } catch (error) {
      console.error("Error:", error) 
    }
  }, [])

  useEffect(() => {
   
    fetchOrders()
  }, [props])
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products`)

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const apiData = await response.json()

    
      if (apiData.products[0]) {
        const columnLabels = []

        const rowsData = apiData.products?.map(item => {
          const stringValue = item.Name
          const descriptionValue = item.Description

      
          const languages = ["en", "si", "ta"] 
          const displayDesc = {}
          const nameDisplay = {}

          languages.forEach(language => {
            const matchName = new RegExp(
              `"${language}"\\s*:\\s*"([^"]+)"`
            ).exec(stringValue)
            const matchDescription = new RegExp(
              `"${language}"\\s*:\\s*"([^"]+)"`
            ).exec(descriptionValue)

            if (matchName && matchName.length >= 2) {
              nameDisplay[language] = matchName[1] 
            } else {
              console.error(
                `No '${language}' value found in the string for Name.`
              )
            }

            if (matchDescription && matchDescription.length >= 2) {
              displayDesc[language] = matchDescription[1]
            } else {
              console.error(
                `No '${language}' value found in the string for Description.`
              )
            }
          })


          return {
            ...item,
            Name_en: nameDisplay["en"],
            Name_si: nameDisplay["si"],
            Name_ta: nameDisplay["ta"],
            Description_en: displayDesc["en"],
            Description_si: displayDesc["si"],
            Description_ta: displayDesc["ta"],

            date: new Date(item.date).toLocaleDateString(), 
            products: item?.items,

            action: (
              <Space size="middle">
                <Button
                  color="primary"
                  onClick={() => {
                    setAddProduct(true)
                    setSelectedProduct(item)
                
                  }}
                >
                  View/Edit
                </Button> 
              </Space>
            ),
            actionDelete: (
              <Popconfirm
                title="Are you sure you want to delete this product?"
                onConfirm={() => deleteProduct(item.ProductID)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            ),
            inStock: (
              <input
                type="checkbox"
                checked={item.inStock === 1}
                readOnly
              />
            ),
          }
        })

        setData({
          columns: [
            ...columnLabels,
            {
              label: "Id",
              field: "ProductID",
              sort: "asc",
              width: 50,
            },
            {
              label: "Name",
              field: "Name_en", 
              sort: "asc",
              width: 50,
            },
            {
              label: "Description",
              field: "Description_en",
              sort: "asc",
              style: {
                maxheight: "3 * 1.2em",
                overflow: "hidden",
                eoverflow: "ellipsis",
                whitespace: "nowrap",
              },
            },
            {
              label: "Retail Price",
              field: "RetailPrice",
              sort: "asc",
              width: 150,
            },
            {
              label: "Wholesale Price",
              field: "WholesalePrice",
              sort: "asc",
              width: 150,
            },

            {
              label: "Wholesale Qty",
              field: "WholesaleQty",
              sort: "asc",
              width: 150,
            },
            {
              label: "Unit",
              field: "Unit",
              sort: "asc",
              width: 20,
            },
            

            {
              label: "Max Limit",
              field: "MaxLimit",
              sort: "asc",
              width: 20,
            },
            {
              label: "Priority",
              field: "priority",
              sort: "asc",
              width: 20,
            },
            {
              label: "In Stock",
              field: "inStock",
              sort: "asc",
              width: 50,
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 150,
            },
            {
              label: "Delete",
              field: "actionDelete",
              width: 50,
            },
          ],
          rows: rowsData,
        })
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  return (
    <React.Fragment>
      {addProduct && (
        <AddProduct
          setAddProduct={setAddProduct}
          addProduct={addProduct}
          actionType={actionType}
          selectedProduct={selectedProduct}
        />
      )}
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
             
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  style={{
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    setActionType("Add")
                    setAddProduct(true)
                    setSelectedProduct({
                      Name: "",
                      Description: "",
                      WholesalePrice: "",
                      MaxLimit: "",
                      priority: "",
                    })
                  }}
                >
                  <i className="ion ion-md-add-circle"></i> Add Product
                </Button>
              </div>
              {data.rows?.length > 0 ? (
                <MDBDataTable responsive bordered data={data} noBottomColumns />
              ) : (
                <div className="text-center">
                  <h3>No Products Found</h3>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(DatatableTables)
