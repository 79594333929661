import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Button } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import cors1Image from "../../assets/images/cors1.png"
import cors1Image1 from "../../assets/images/cors2.png"
import cors1Image2 from "../../assets/images/cors3.png"

const CORSEXT = (props) => {
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "CorsExtension", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("CorsExtension", breadcrumbItems)
  }, [])

  const handleExtensionClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf",
      "_blank"
    )
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "15vh",
      }}
    >
      <Button
        color="primary"
        onClick={handleExtensionClick}
        style={{
          width: "250px",
          height: "50px",
          fontSize: "18px",
          margin: "20px", // Add margin to give space between the button and images
        }}
      >
        Extension Download
      </Button>

      {/* Add your images below */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%", // Ensure images take the full width
        }}
      >
        <img
          src={cors1Image}
          alt="First Image"
          style={{ width: "400px", height: "300px" }}
        />
        <img
           src={cors1Image1}
          alt="Second Image"
          style={{ width: "500px", height: "100px" }}
        />
        <img
          src={cors1Image2}
          alt="Third Image"
          style={{ width: "300px", height: "300px" }}
        />
      </div>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(CORSEXT)
